import axios from 'axios';
import { ROOT_URL } from '@appmaker/core/api/core';

const logoutUser = async () => {
	try {
		const response = await axios.get(`${ROOT_URL}/v2/user/logout`, {
			credentials: 'include',
			withCredentials: true,
		});
		return response?.data?.return_url;
	} catch (error) {}
};

export { logoutUser };